var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"content-class":"white--tooltip","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","depressed":"","small":"","color":'white'},on:{"click":function($event){(_vm.mode == 'move' && _vm.foldersAvailable[0].children.length == 0) ? '' : _vm.openMoveDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":_vm.mode == 'move' ? 'grey' : 'success'}},[_vm._v(_vm._s(_vm.mode == 'move' ? '$arrows_l' : '$trasharrowup_d'))])],1)]}}])},[_c('span',[_vm._v(_vm._s((_vm.mode == 'move' && _vm.foldersAvailable[0].children.length == 0) ? _vm.$t('noFoldersAvailable') : (_vm.mode == 'move' ? _vm.$t('moveItem') : _vm.$t('restoreItem'))))])]),_c('v-dialog',{attrs:{"width":"400","scrollable":""},model:{value:(_vm.openMoveDialog),callback:function ($$v) {_vm.openMoveDialog=$$v},expression:"openMoveDialog"}},[_c('v-card',{staticClass:"pa-2"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.mode == 'move' ? _vm.$t('moveItemTitle') : _vm.$t('restoreItemTitle'))+" ")]),_c('v-card-text',{staticClass:"pb-0"},[(_vm.openMoveDialog)?_c('v-treeview',{key:'treeMove_'+_vm.treeKeyEdit,ref:"treeSelectFolder",attrs:{"items":_vm.foldersAvailable,"expand-icon":'$dropdown',"active":_vm.tree,"activatable":"","hoverable":"","item-key":'id',"item-text":"title","open-all":""},on:{"update:active":_vm.onOpen},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(!item.hasOwnProperty('root'))?_c('div',[_vm._v(" "+_vm._s(item.title)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t('rootfolder'))+" ")])]}},{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(!item.hasOwnProperty('root'))?_c('div',{staticClass:"folderIcon mr-2"},[_c('v-icon',{attrs:{"color":"#F8D775","size":"18"}},[_vm._v(" "+_vm._s(open ? '$folderopen_d' : '$folder_d')+" ")]),(item.is_editable && item.private && item.holder && item.holder[0] && item.holder[0] != _vm.userEntity)?_c('v-avatar',{staticClass:"lightgrey avatarFolder",attrs:{"size":"14"}},[_c('v-icon',{attrs:{"size":"10","color":'darkgrey'}},[_vm._v(" "+_vm._s('$usersecret_d')+" ")])],1):_vm._e()],1):_c('div',[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s('$home_d')+" ")])],1)]}}],null,false,3308471020),model:{value:(_vm.tree),callback:function ($$v) {_vm.tree=$$v},expression:"tree"}}):_vm._e()],1),_c('v-card-actions',{staticClass:"mt-2 justify-end"},[_c('v-btn',{attrs:{"depressed":"","small":"","loading":_vm.loading,"color":"success"},on:{"click":_vm.moveItem}},[_vm._v(" "+_vm._s(_vm.mode == 'move' ? _vm.$t('move') : _vm.$t('restore'))+" ")]),_c('v-btn',{attrs:{"depressed":"","small":"","loading":_vm.loading,"color":"primary"},on:{"click":function($event){_vm.openMoveDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }